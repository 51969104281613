// Helpers
import { Component } from "./helpers/component";
import { Lazyload } from "./helpers/lazyload";
// import { Utils } from "./helpers/utils";

// Vendors
// import MatchHeight from '@tannerhodges/match-height';
import { Collapse } from 'bootstrap';
import Aos from "aos";

// Importing components
import { Slider } from "../../components/slider/slider";

Component.register("Slider", Slider);

// Utils.credits();

// On DOM ready
document.addEventListener('DOMContentLoaded', () => {

  // Inits the helper module who lazy-load the images
  Lazyload.init();

  // Inits the components in page
  Component.initAll();

  // Timer1
  ; ((el, timerId) => {
    const countDownDate = new Date(el.dataset.targetTime).getTime();
    const fn = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      el.querySelector('.timer-days-value').innerHTML = days;
      el.querySelector('.timer-hours-value').innerHTML = hours;
      el.querySelector('.timer-minutes-value').innerHTML = minutes;
      el.querySelector('.timer-seconds-value').innerHTML = seconds;

      if (distance < 0) {
        clearInterval(window[timerId]);
        el.classList.add('expired');
      }
    };
    fn();
    window[timerId] = setInterval(fn, 1000);
  })(document.getElementById("timer1"), "timer1");

  // Animations
  Aos.init();

});

$(document).on("click", 'a[href][data-scrollto="true"]', (e) => {
  try {
    let $btn = $(e.target);
    $btn = $btn.is("a[href]") ? $btn : $btn.closest("a[href]");
    let $target = $($btn.attr("href"));
    if ($target.length > 0) {
      e.preventDefault();
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $target.offset().top - 16 * 6,
        },
        1000
      );
    }

    $('[aria-controls="navMain"][aria-expanded="true"]').trigger('click');
  } catch (error) { }
});
